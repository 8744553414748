<template>
  <div class="user-management">
    <div class="page-info">
      <div class="page-title">
        <p class="title-1">User Management</p>
      </div>

      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <!-- viewMode head -->
      <div class="d-flex mb-4 gutter-mx-3 search-toolbar" v-if="!editMode">
        <!-- Column Select -->
        <div class="column-filter">
          <v-autocomplete dense solo hide-details flat outlined :items="searchColumns" v-model="searchColumn">
            <template v-slot:append>
              <v-icon> mdi-chevron-down </v-icon>
            </template>
          </v-autocomplete>
        </div>

        <!-- Column Search -->
        <div class="search">
          <v-text-field
            dense
            outlined
            hide-details
            v-model="searchText"
            placeholder="Insert Keyword to search"
            background-color="white"
            @keyup.enter="getUsers(true)"></v-text-field>
        </div>

        <v-btn color="secondary" icon @click="getUsers(true)" style="margin: 0; align-self: center">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>

        <v-spacer />

        <v-btn color="secondary" v-if="isManager" dark :elevation="0" @click="changeEditMode(true)">MODIFY</v-btn>
      </div>

      <!-- editMode head -->
      <div class="d-flex mb-4 gutter-mx-3" v-else>
        <v-spacer />

        <v-btn color="secondary" dark :elevation="0" :loading="saveLoading" @click="save">SAVE</v-btn>
        <v-btn color="secondary" dark :elevation="0" @click="changeEditMode(false)">CANCEL</v-btn>
      </div>

      <!-- #region custom-table -->
      <div class="custom-table">
        <table>
          <thead>
            <tr>
              <th v-for="column in tableColumns" :key="column.text">
                {{ column.text }}
              </th>
              <th v-if="editMode"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, userIndex) in users" :key="`${userIndex} ${user.id}`">
              <!-- No -->
              <td>
                {{ totalCnt - userIndex - (page - 1) * itemsPerPage }}
              </td>

              <!-- Email -->
              <td>
                {{ user.email }}
              </td>

              <!-- Name -->
              <td>
                {{ user.name }}
              </td>

              <!-- phone -->
              <td>
                {{ user.mobilePhone }}
              </td>

              <!-- Status -->
              <td
                :class="isManager && isWaiting(user.isUse) ? 'underline pointer' : ''"
                @click="isManager && isWaiting(user.isUse) && joinRequestClicked(user)">
                <span v-if="!editMode || isWaiting(user.isUse)">
                  {{ convertStatus(user.isUse) }}
                </span>

                <v-autocomplete
                  v-else
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="[
                    { text: 'ACTIVE', value: 'Y' },
                    { text: 'DEACTIVE', value: 'E' },
                  ]"
                  :disabled="user.email === $store.state.user.email"
                  v-model="user.isUse">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </td>

              <!-- Authorization -->
              <td>
                <span v-if="!editMode || isWaiting(user.isUse)">
                  {{ `${isWaiting(user.isUse) ? "" : user.userRole === "M" ? "MANAGER" : "MEMBER"}` }}
                </span>
                <v-autocomplete
                  v-else
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="[
                    { text: 'MANAGER', value: 'M' },
                    { text: 'MEMBER', value: 'G' },
                  ]"
                  :disabled="user.email === $store.state.user.email"
                  v-model="user.userRole">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </td>

              <!-- Modified date -->
              <td>
                {{ $m(user.sysUdtDt).format("yyyy-MM-DD HH:mm") }}
              </td>

              <!-- Row Delete -->
              <td v-if="editMode">
                <v-btn
                  v-if="!isWaiting(user.isUse)"
                  color="secondary"
                  outlined
                  fab
                  small
                  @click="deleteClicked(user)"
                  :disabled="user.email === $store.state.user.email"
                  :loading="user.deleteLoading">
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- pagination을 editMode일때 안보여주는 이유 : -->
        <!-- editMode일 때, add Item을 했다하여 현재 아이템 중 1개를 뒤페이지로 보내지 않고, -->
        <!-- remove Item을 했다하여 아이템을 1개 당겨오지도 않음. -->
        <!-- page 이동 시, 새로운 item을 받아오면 기존 수정한 내용도 날아감. -->
        <div class="text-center pt-2" v-if="!editMode && pageCount > 0">
          <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
        </div>
      </div>
    </v-card>

    <confirm
      :show.sync="confirmShow"
      :confirmButton="{ text: 'CONFIRM', params: targetUser }"
      :cancelButton="{ text: 'REJECT' }"
      @confirm="approbation"
      @cancel="rejectClicked">
      <span>
        {{ `${targetUser.email} (${targetUser.name}) requested to Join` }}
      </span>
    </confirm>

    <confirm
      :show.sync="rejectConfirmShow"
      :confirmButton="{ text: 'REJECT', params: targetUser }"
      :cancelButton="{ text: 'CANCEL' }"
      :hideEvent="true"
      @confirm="rejection"
      @cancel="rejectCancel">
      <span> Please fill out the reason for rejection. </span>
      <v-textarea outlined color="secondary" v-model="targetUser.rejectReason"></v-textarea>
    </confirm>

    <confirm :show.sync="deleteConfirmShow" @confirm="deleteUser">
      <span> you want to delete the selected user? </span>
    </confirm>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { hasValue, _deepCopy } from "@/utils/util";
import { company as statusHandler, _isUse } from "@/utils/statusHandler";
import codeHandler from "@/utils/codeHandler";
import Confirm from "@/components/common/cards/Confirm.vue";
import messageHandler from "@/utils/messageHandler";
export default {
  components: { Confirm },
  data() {
    return {
      editMode: false,
      users: [],
      backupUsers: [],
      searchColumn: "ALL",
      searchText: "",
      saveLoading: false,
      loading: false,
      page: 1,
      totalCnt: 0,
      itemsPerPage: 10,
      confirmShow: false,
      targetUser: {},
      rejectConfirmShow: false,
      deleteConfirmShow: false,
      deleteTargetUser: {},
      breadcrumbs: [
        {
          text: "System",
          disabled: true,
        },
        {
          text: "User Management",
          disabled: true,
        },
      ],
      columns: [
        //text: 컬럼 이름, tableShow: 테이블 표시 여부, searchShow: 셀렉트박스 표시 여부, value: 키값
        { text: "ALL", tableShow: false },
        { text: "No", value: "No", searchShow: false },
        { text: "USER ID (E-MAIL)", value: "email" },
        { text: "NAME", value: "name" },
        { text: "MOBILE PHONE NUMBER", value: "mobilePhone" },
        { text: "STATUS", value: "isUse" },
        { text: "AUTHORIZATION", value: "userRole" },
        { text: "MODIFIED DATE", value: "sysUdtDt", searchShow: false },
      ],
    };
  },
  methods: {
    changeEditMode(val, notChange) {
      if (val === true) {
        this.backupUsers = _deepCopy(this.users);
      } else {
        if (!notChange) this.users = _deepCopy(this.backupUsers);
        else this.getUsers();
      }

      this.totalCnt = this.users[0]?.totalCnt;

      this.editMode = val;
    },
    getUsers(changeFilters) {
      this.loading = true;
      if (changeFilters) this.page = 1;

      this.$http
        .get("/company/user/list", {
          data: {
            cmpyUUID: this.$store.getters.getCmpyUUID,
            currentPageNo: this.page,
            filterColumn: this.searchColumn,
            searchText: this.searchText,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            if (Array.isArray(res.data.data)) {
              this.users = res.data.data.map(u => ({ ...u, deleteLoading: false }));
              this.totalCnt = res.data.data[0]?.totalCnt || 0;
            }
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    convertStatus(isUse) {
      switch (isUse) {
        case _isUse.Joined:
          return "ACTIVE";
        case _isUse.Waiting:
          return "JOIN REQUEST";
        case _isUse.Exit:
          return "DEACTIVE";
        default:
          return "-";
      }
    },
    joinRequestClicked(user) {
      this.targetUser = user;
      this.confirmShow = true;
    },
    approbation() {
      if (!hasValue(this.targetUser.id)) return this.$toast.error("Unknown Error");

      const updateUser = {
        id: this.targetUser.id,
        isUse: _isUse.Joined,
      };

      this.$http
        .put("/company/user", {
          companyUserUpdateDtoList: [updateUser],
          cmpyUUID: this.$store.getters.getCmpyUUID,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success(`Confirmed to Join ${this.targetUser.email} (${this.targetUser.name})`);
            this.confirmShow = false;
            this.targetUser.isUse = _isUse.Joined;
          } else codeHandler(res.data);
        });
    },
    rejectClicked() {
      this.confirmShow = false;
      this.rejectConfirmShow = true;
    },
    rejection() {
      if (!hasValue(this.targetUser.id)) return this.$toast.error("Unknown Error");

      const updateUser = {
        id: this.targetUser.id,
        isUse: _isUse.Denied,
        rejectReason: this.targetUser.rejectReason,
      };

      this.$http
        .put("/company/user", {
          companyUserUpdateDtoList: [updateUser],
          cmpyUUID: this.$store.getters.getCmpyUUID,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success(`Rejected to Join ${this.targetUser.email} (${this.targetUser.name})`);
            this.rejectConfirmShow = false;
            this.getUsers();
          } else codeHandler(res.data);
        });
    },
    rejectCancel() {
      this.rejectConfirmShow = false;
      this.targetUser.rejectReason = "";
    },
    deleteClicked(user) {
      this.deleteTargetUser = user;
      this.deleteConfirmShow = true;
    },
    deleteUser() {
      if (!hasValue(this.deleteTargetUser.id)) return this.$toast.error("Unknown Error");

      const updateUser = {
        id: this.deleteTargetUser.id,
        isUse: _isUse.NotUsed,
      };

      this.deleteTargetUser.deleteLoading = true;

      this.$http
        .put("/company/user", {
          companyUserUpdateDtoList: [updateUser],
          cmpyUUID: this.$store.getters.getCmpyUUID,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.users.splice(
              this.users.findIndex(u => u.id === this.deleteTargetUser.id),
              1,
            );

            this.backupUsers.splice(
              this.backupUsers.findIndex(u => u.id === this.deleteTargetUser.id),
              1,
            );

            this.totalCnt -= 1;
          } else codeHandler(res.data);
        })
        .finally(() => (this.deleteTargetUser.deleteLoading = false));
    },
    save() {
      let breakReason = "";
      const companyUserUpdateDtoList = [];
      for (let userIndex = 0; userIndex < this.users.length; userIndex++) {
        const user = this.users[userIndex];

        //#region 변경사항 체크
        let changed = false;
        const orgUser = this.backupUsers.find(u => u.id === user.id);
        if (!orgUser) continue;

        changed = orgUser.isUse !== user.isUse || orgUser.userRole !== user.userRole;
        //#endregion

        //#region 파라미터 생성
        if (changed) {
          //#region 필수값 체크
          if (![user.id, user.isUse, user.userRole].every(hasValue)) {
            breakReason = "error";
            break;
          }
          //#endregion

          const companyUserUpdateDto = {
            id: user.id,
            isUse: user.isUse,
            userRole: user.userRole,
          };

          companyUserUpdateDtoList.push(companyUserUpdateDto);
        }
        //#endregion
      }

      if (breakReason !== "") return messageHandler(breakReason);

      this.saveLoading = true;
      this.$http
        .put("/company/user", { companyUserUpdateDtoList, cmpyUUID: this.$store.getters.getCmpyUUID })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Successfully saved");
            this.changeEditMode(false, true);
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    isWaiting: statusHandler.isWaiting,
    hasValue,
  },
  computed: {
    tableColumns() {
      return this.columns.filter(c => c.tableShow !== false);
    },
    searchColumns() {
      return this.columns.filter(c => c.searchShow !== false);
    },
    pageCount() {
      return Math.ceil((this.totalCnt || 0) / this.itemsPerPage);
    },
    isManager() {
      const cmpy = this.$store.getters["user/getCompanyList"]?.find(
        c => c.cmpyUUID === this.$store.getters.getCmpyUUID,
      );
      return cmpy?.userRole === "M";
    },
  },
  watch: {
    page() {
      this.getUsers();
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style></style>
