import Vue from "vue";

const messageHandler = function (type) {
  switch (type) {
    case "required":
      Vue.$toast.warning("Please enter all the required items");
      break;
    case "number":
      Vue.$toast.warning("Please only be entered with numbers");
      break;
    case "styleRequired":
      Vue.$toast.warning("StyleNumber is required items");
      break;
    case "styleDupl":
      Vue.$toast.warning("StyleNumber is cannot be duplicated");
      break;
    case "error":
      Vue.$toast.warning("An unknown error has occurred.\r\nPlease contact administrator.");
      break;
    case "other":
    default:
      break;
  }
};

export default messageHandler;
